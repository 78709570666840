import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { navigate } from "gatsby";
import React, { useEffect, useState } from "react";
import RightArrowWhite from "../../assets/Icons/accordian_white.svg";
import RightArrow from "../../assets/Icons/go.svg";
import HoverCardWithBtnIcon2 from "../../assets/Icons/Group_1.svg";
import HoverCardWithBtnIcon3 from "../../assets/Icons/Group_2.svg";
import HoverCardWithBtnIcon5 from "../../assets/Icons/Group_3.svg";
import HoverCardWithBtnIcon4 from "../../assets/Icons/Group_3042.svg";
import Clm2Img from "../../assets/Icons/mail.svg";
import HoverCardWithBtnIcon1 from "../../assets/Icons/outrun.svg";
import Clm1Img from "../../assets/Icons/phone.svg";
import BackArrowIcon from "../../assets/Images/accordian.svg";
import BannerImage from "../../assets/Images/caseStudy/takealook.png";
import clutchLogo from "../../assets/Images/WhatWeDo/clutch-co-vector-logo.svg";
import quote from "../../assets/Images/WhatWeDo/quote.svg";
import Button from "../../components/Button";
import Layout from "../../components/layouts/layout";
import DataService from "../../services/api/data_services";
import { BASE_SITE_URL } from "../../shared/constants";
import ContactUsRightGrid from "../../templates/ContactUsRightGrid";
import ImageAvatar from "../../templates/ImageAvatar";
import ImageBanner from "../../templates/ImageBanner";
import LeftGrid from "../../templates/LeftGrid";
import Miniview from "../../templates/MiniView";
import ShareCard from "../../templates/ShareCard";
import TwoColumnContactInfo from "../../templates/TwoColumnContactInfo";
import ViewPdfRightGrid from "../../templates/ViewPdfRightGrid";

const WhitePaper = ({ pageContext: { whitepaper } }) => {
  const [page, setPage] = useState(1);
  const [clientStoryData, setClientStoryData] = useState([]);
  const [clientStoryRawData, setClientStoryRawData] = useState([]);
  const [whitePaperContent, setwhitePaperContent] = useState([]);
  const queryString =
    typeof window !== "undefined" ? window.location.href.split("/")[4] : "";
  const [isHover, setIsHover] = useState(false);
  const toggleHover = () => {
    setIsHover(!isHover);
  };

  useEffect(() => {
    DataService.getData(
      `/admin/api/post?limit=${3}&page=${page}&page_id=1&type=Client Story&component=mini_view`
    ).then((response) => {
      setClientStoryData([...clientStoryData, ...response.data]);
      setClientStoryRawData(response);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);
  useEffect(() => {
    DataService.getData(`/admin/api/post-details?slug=${queryString}`)
      .then((response) => {
        const whitePaperContent = response.data;
        setwhitePaperContent(whitePaperContent);
      })
      .catch((error) => {
        navigate(-1);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryString]);
  /**
   * Image Banner
   */
  const ImageBannerData = {
    bnrImg: whitePaperContent.image,
    component: "Who",
    bannerImageAlt: whitePaperContent.bannerImageAlt,
    bnrImgTitle: whitePaperContent.banner_image_title,
  };

  const miniviewData2 = {
    height: "490",
    service: "machineLearning",
    title: "Let's Talk",
    subTitle: "We'd love to help with your product.",
    variant: "contained",
    label: "GET IN TOUCH",
    customClass: "miniCrdBgImg-main-border",
    route: "/contact-us",
  };

  /**
   * TwoColumnContactInfo
   */
  const TwoColumnContactInfoData = {
    Clm1Img: Clm1Img,
    Clm1Title: "Phone:",
    Clm1SubTitle: "+44 (0) 161 706 7983",
    Clm2Img: Clm2Img,
    Clm2Title: "Email:",
    Clm2SubTitle: "hello@digiryte.com",
  };

  /**
   * Six Hover Card
   */
  const SixHoverCardData = {
    HoverCardWithBtnIcon1: HoverCardWithBtnIcon1,
    HoverCardWithBtnIcon2: HoverCardWithBtnIcon2,
    HoverCardWithBtnIcon3: HoverCardWithBtnIcon3,
    HoverCardWithBtnIcon4: HoverCardWithBtnIcon4,
    HoverCardWithBtnIcon5: HoverCardWithBtnIcon5,
    HoverCardWithBtnContent1: whitePaperContent.secondary_description,
    keyPointsBig1: whitePaperContent.key_points_big,
    keyPointsBig2: whitePaperContent.key_points_big1,
    keyPointsBig3: whitePaperContent.key_points_big2,
    keyPoints1: whitePaperContent.key_points,
    keyPoints2: whitePaperContent.key_points1,
    keyPoints3: whitePaperContent.key_points2,
    route: "/contact-us",
  };

  const VideobannerData = {
    variant: "contained",
    color: "primary",
    label: "TAKE A LOOK",
    tooltip: "no",
    ImageBannerTitle: "Digital Products, Built Ryte!",
    ImageBannerSubTitle: (
      <>
        Build Next Generation Applications <br /> With UK’s Leading Software
        Consultancy
      </>
    ),
    BannerImage: BannerImage,
    route: whitePaperContent.pdf_file,
  };
  const quotedMiniCardData = {
    title: "Mobile App Dev for Automative Sales App",
    heading:
      "We can’t speak highly enough of Digiryte’s team because they bent over backward to help us launch our product.",
    content: "Founder, Bumper UK",
    footerContent: (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div>Authentic client review from</div>
        <div style={{ paddingLeft: "5px" }}>
          <img src={clutchLogo} alt="Clutch" height="50px" />
        </div>
      </div>
    ),
  };

  /**
   * Image avatar component
   */
  const imageAvatarData = {
    image: whitePaperContent.author_image,
    alt: whitePaperContent.author_name,
    name: whitePaperContent.author_name,
    date: whitePaperContent.published_date,
    title: whitePaperContent.position,
  };

  /**
   * Image avatar component
   */
  const imageAvatarData1 = {
    image: whitePaperContent.corresponding_author_image,
    alt: whitePaperContent.corresponding_author,
    name: whitePaperContent.corresponding_author,
    // date:whitePaperContent.published_date,
    title: whitePaperContent.co_author_position,
  };

  return (
    <React.Fragment>
      <Layout
        seo={{
          title: whitePaperContent?.og_title,
          ogtitle: whitepaper?.og_title,
          description: whitepaper
            ? whitepaper?.og_description
            : whitePaperContent?.og_description,
          ogurl: `${BASE_SITE_URL}/white-paper/${whitepaper?.slug}/`,
          ogimage: whitepaper?.og_image,
        }}
      >
        <ImageBanner imageBannerData={ImageBannerData} />
        <div className="mt-50 body-container">
          <Button
            icon={
              <img
                className="effect-btn-svgicon"
                src={BackArrowIcon}
                alt="click"
              />
            }
            customClassName="effect-btn"
            className="mui-button-font"
            tip="White Papers"
            routepath="/insights/white-papers"
          />
          <Typography
            style={{
              fontSize: "24px",
              fontWeight: "600",
              marginTop: "-42px",
              marginLeft: "40px",
            }}
          >
            White Papers
          </Typography>
        </div>

        <div id="html-content"></div>
        <div className="mt-30 body-container">
          {whitePaperContent && whitePaperContent.primary_description_1 && (
            <div
              className="dynamic-description-one"
              dangerouslySetInnerHTML={{
                __html: `${whitePaperContent.primary_description_1}`,
              }}
            />
          )}
          <div className="mt-30"></div>
          {whitePaperContent && whitePaperContent.primary_description_2 && (
            <div
              className="dynamic-description-one"
              dangerouslySetInnerHTML={{
                __html: `${whitePaperContent.primary_description_2}`,
              }}
            />
          )}
          <div className="mt-30"></div>
          {whitePaperContent && whitePaperContent.primary_description_3 && (
            <div
              className="dynamic-description-one"
              dangerouslySetInnerHTML={{
                __html: `${whitePaperContent.primary_description_3}`,
              }}
            />
          )}

          <Grid container direction="row" spacing={3}>
            <Grid style={{ position: "relative" }} item xs={12} md={2}>
              {whitePaperContent &&
                whitePaperContent.author_name &&
                whitePaperContent.author_image && (
                  <ImageAvatar imageAvatarData={imageAvatarData} />
                )}
              {whitePaperContent &&
                whitePaperContent.corresponding_author &&
                whitePaperContent.corresponding_author_image && (
                  <ImageAvatar imageAvatarData={imageAvatarData1} />
                )}
              {whitePaperContent && whitePaperContent.primary_description_1 && (
                <span>
                  <ShareCard
                    url={`${BASE_SITE_URL}/white-paper/${
                      whitePaperContent && whitePaperContent.slug
                    }`}
                    type={whitePaperContent.slug_type}
                    slug={whitePaperContent.slug}
                  />
                </span>
              )}
            </Grid>
            <Grid item xs={12} md={8}>
              {whitePaperContent.testimonial &&
                whitePaperContent.testimonial.map((testimonial) => (
                  <div
                    style={{ position: "relative" }}
                    className="mt-50 body-container"
                  >
                    <div className="dynamic-quote-white">
                      <img alt="quotes" src={quote} />
                    </div>
                    <Typography className="testimonial-content">
                      {testimonial.content}
                    </Typography>
                    <Typography className="testimonial-client mt-20">
                      - {testimonial.client_name}
                    </Typography>
                  </div>
                ))}
            </Grid>
          </Grid>
        </div>
        {whitePaperContent.video_url !== null && (
          <div className="mt-50 body-container">
            {/* <iframe
            src={whitePaperContent.video_url}
            frameborder="0"
            style={{ borderRadius: "10px" }}
            allow="autoplay; encrypted-media"
            allowfullscreen
            title={whitePaperContent.video_description}
            width="100%"
            height="495"
          ></iframe> */}
            <video autoPlay playsInline loop muted id="myVideo">
              <source src={whitePaperContent.video_url} type="video/mp4" />
            </video>
          </div>
        )}
        <div className="article-main mt-30 body-container">
          {whitePaperContent.video_description}
        </div>

        <div className="mt-50 body-container" id="gated">
          <div className="title-header">
            {whitePaperContent.secondary_title}
          </div>
        </div>
        <div className="mt-50 body-container">
          <Grid container direction="row" spacing={3}>
            <Grid item xs={12} sm={12} md={6} className="card crd-spc">
              <LeftGrid SixHoverCardData={SixHoverCardData} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} className="card crd-spc">
              {whitePaperContent &&
              whitePaperContent.show_contact_form === 1 ? (
                <ContactUsRightGrid
                  bannerTitle={whitePaperContent.banner_image_title}
                  pdfFile={whitePaperContent.pdf_file}
                  articleid={whitePaperContent.id}
                  type="white-paper"
                />
              ) : (
                <ViewPdfRightGrid VideobannerData={VideobannerData} />
              )}
            </Grid>
          </Grid>
        </div>
        {clientStoryData && clientStoryData.length > 0 && (
          <React.Fragment>
            <div className="body-container mt-70">
              <h2 className="title-header ttc">
                <span className="red-line3letter">Fea</span>tured Client stories
              </h2>
            </div>
            <div className="mt-50">
              <Miniview
                miniviewData={clientStoryData}
                miniviewData2={miniviewData2}
                total={clientStoryData.length}
                quotedMiniCardData={quotedMiniCardData}
              />
            </div>
          </React.Fragment>
        )}
        <div style={{ width: "100%", textAlign: "center" }}>
          <div
            onMouseEnter={toggleHover}
            onMouseLeave={toggleHover}
            className="mt-60"
            role="presentation"
            onClick={() => setPage(page + 1)}
            style={{ display: "inline-block" }}
          >
            {clientStoryRawData.has_more && (
              <Button
                icon={
                  <img
                    className="effect-btn-svgicon"
                    src={!isHover ? RightArrow : RightArrowWhite}
                    alt="MORE CLIENT STORIES"
                  />
                }
                customClassName="info-view-resize-btn"
                color="primary"
                variant="outlined"
                label="MORE CLIENT STORIES"
              />
            )}
          </div>
        </div>
        <div className="mt-70"></div>
        <TwoColumnContactInfo
          TwoColumnContactInfoData={TwoColumnContactInfoData}
        />
      </Layout>
    </React.Fragment>
  );
};

export default WhitePaper;
